<template>
  <BaseSlugPage :filters="filters" />
</template>

<script setup>
import BaseSlugPage from "~/nuxt_modules/blog/ui/components/BaseSlugPage.vue";

definePageMeta({
  key: "postsByCategory",
});

const route = useRoute();

const filters = computed(() => {
  return {
    category: {
      slug: route.params.categorySlug,
    },
  };
});
</script>

<style scoped lang="scss"></style>
